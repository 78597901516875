import NotFoundPage from '@components/NotFoundPage'

import { graphql } from 'gatsby'

export const query = graphql`
  query {
    allContentful404Page {
      edges {
        node {
          title
          heading
          link {
            url
            label
          }
        }
      }
    }
  }
`

export default NotFoundPage

// BELOW IS WHAT USED TO EXIST FOR THE CAPITAL PAGE
// IT IS NO LONGER USED, SO I AM USING THE NotFoundPage AS A WORKAROUND
// BECAUSE GATSBY CACHES IT OTHERWISE AND DISPLAYS THE CAPTIAL PAGE ANYWAY

// import Group1001CapitalPage from '@components/Group1001CapitalPage'
// import { graphql } from 'gatsby'

// export const query = graphql`
//   query {
//     allContentfulGroup1001CapitalPage {
//       edges {
//         node {
//           title
//           heading
//           body {
//             internal {
//               content
//             }
//           }
//           statistics {
//             value
//             description
//           }
//         }
//       }
//     }
//   }
// `

// export default Group1001CapitalPage
